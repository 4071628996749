<template>
  <div class="card mt-5 shadow bg-light">
    <div class="text-center">
      <img src="../assets/images/bemag-full-grey-500.png" class="card-img-top img-fluid" style="max-width: 600px" alt="Bemag">
    </div>

    <div class="card-body">

      <div class="text-center">
        <h3 class="card-title">Recuperar contraseña</h3>
      </div>

      <div class="form-group">
        <label for="usuario">Usuario o correo:</label>
        <input type="text" class="form-control" v-model="usuario">
      </div>

      <div class="text-center">
        <LoadingButton @clicked="recuperarpasswd" :iconProp="'fas fa-paper-plane'" :loadingProp="loading" :classProp="'btn btn-success'" :textProp="'Enviar'" class="m-1"></LoadingButton>
        <router-link to="/login" class="btn m-1 btn-secondary"><i class="fas fa-arrow-left"></i> Volver</router-link>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        loading: false,
        usuario: null,
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      recuperarpasswd: async function () {
        try {
          this.loading = true

          const res = await axios.post(config.apiUrl + '/usuarios/recuperarpasswd', {
            usuario: this.usuario,
          })

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
          })

          this.$router.push('login')
        } catch (e) {
          this.loading = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPublicLayout')
    }
  }
</script>
